import { INSERT_FILE_COMMAND } from '../Commands';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { FileNode } from './Nodes/FileNode';
import { $createParagraphNode, $insertNodes, COMMAND_PRIORITY_EDITOR, COMMAND_PRIORITY_NORMAL, PASTE_COMMAND, $isRootOrShadowRoot, } from 'lexical';
import { $createFileNode } from './Nodes/FileUtils';
import { mergeRegister, $wrapNodeInElement } from '@lexical/utils';
import { useFilesController } from '@/Controllers/FilesControllerProvider';
import { FilesControllerEvent } from '@/Controllers/FilesController';
import { useLinkingController } from '@/Controllers/LinkingControllerProvider';
import { useApplication } from '@/Components/ApplicationProvider';
export default function FilePlugin({ currentNote }) {
    const application = useApplication();
    const [editor] = useLexicalComposerContext();
    const filesController = useFilesController();
    const linkingController = useLinkingController();
    useEffect(() => {
        if (!editor.hasNodes([FileNode])) {
            throw new Error('FilePlugin: FileNode not registered on editor');
        }
        const uploadFilesList = (files) => {
            Array.from(files).forEach(async (file) => {
                try {
                    const uploadedFile = await filesController.uploadNewFile(file);
                    if (uploadedFile) {
                        editor.dispatchCommand(INSERT_FILE_COMMAND, uploadedFile.uuid);
                        void linkingController.linkItemToSelectedItem(uploadedFile);
                        void application.changeAndSaveItem.execute(uploadedFile, (mutator) => {
                            mutator.protected = currentNote.protected;
                        });
                    }
                }
                catch (error) {
                    console.error(error);
                }
            });
        };
        return mergeRegister(editor.registerCommand(INSERT_FILE_COMMAND, (payload) => {
            const fileNode = $createFileNode(payload);
            $insertNodes([fileNode]);
            if ($isRootOrShadowRoot(fileNode.getParentOrThrow())) {
                $wrapNodeInElement(fileNode, $createParagraphNode).selectEnd();
            }
            const newLineNode = $createParagraphNode();
            fileNode.getParentOrThrow().insertAfter(newLineNode);
            return true;
        }, COMMAND_PRIORITY_EDITOR), editor.registerCommand(PASTE_COMMAND, (payload) => {
            var _a;
            const files = payload instanceof ClipboardEvent ? (_a = payload.clipboardData) === null || _a === void 0 ? void 0 : _a.files : null;
            if (files === null || files === void 0 ? void 0 : files.length) {
                uploadFilesList(files);
                return true;
            }
            return false;
        }, COMMAND_PRIORITY_NORMAL), editor.registerNodeTransform(FileNode, (node) => {
            /**
             * When adding the node we wrap it with a paragraph to avoid insertion errors,
             * however that causes issues with selection. We unwrap the node to fix that.
             */
            const parent = node.getParent();
            if (!parent) {
                return;
            }
            if (parent.getChildrenSize() === 1) {
                parent.insertBefore(node);
                parent.remove();
            }
        }));
    }, [application, currentNote.protected, editor, filesController, linkingController]);
    useEffect(() => {
        const disposer = filesController.addEventObserver((event, data) => {
            if (event === FilesControllerEvent.FileUploadedToNote) {
                const fileUuid = data[FilesControllerEvent.FileUploadedToNote].uuid;
                editor.dispatchCommand(INSERT_FILE_COMMAND, fileUuid);
            }
        });
        return disposer;
    }, [filesController, editor]);
    return null;
}
