import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NoPreviewIllustration } from '@standardnotes/icons';
import { useState } from 'react';
import Button from '../Button/Button';
import { FileItemActionType } from '../AttachedFilesPopover/PopoverFileItemAction';
/**
 * Some formats require using the <source/> tag with the type attribute
 * because we use object URLs. However some formats seem to only work
 * when not using the <source/> tag.
 * We show an error message if neither works.
 */
const VideoPreview = ({ file, filesController, objectUrl }) => {
    const [showError, setShowError] = useState(false);
    const [shouldTryFallback, setShouldTryFallback] = useState(false);
    if (showError) {
        return (_jsxs("div", { className: "flex flex-grow flex-col items-center justify-center", children: [_jsx(NoPreviewIllustration, { className: "mb-4 h-30 w-30" }), _jsx("div", { className: "mb-2 text-base font-bold", children: "This video can't be previewed." }), _jsx("div", { className: "mb-4 max-w-[35ch] text-center text-sm text-passive-0", children: "To view this file, download it and open it using another application." }), _jsx(Button, { primary: true, onClick: () => {
                        filesController
                            .handleFileAction({
                            type: FileItemActionType.DownloadFile,
                            payload: { file },
                        })
                            .catch(console.error);
                    }, children: "Download" })] }));
    }
    if (shouldTryFallback) {
        return (_jsx("video", { className: "h-full w-full", controls: true, autoPlay: true, src: objectUrl, onError: () => {
                setShowError(true);
                setShouldTryFallback(false);
            } }));
    }
    return (_jsx("video", { className: "h-full w-full", controls: true, autoPlay: true, onError: () => {
            setShouldTryFallback(true);
        }, children: _jsx("source", { src: objectUrl, type: file.mimeType }) }));
};
export default VideoPreview;
