import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { STRING_NON_MATCHING_PASSWORDS } from '@/Constants/Strings';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState, } from 'react';
import { AccountMenuPane } from './AccountMenuPane';
import Button from '@/Components/Button/Button';
import Checkbox from '@/Components/Checkbox/Checkbox';
import DecoratedPasswordInput from '@/Components/Input/DecoratedPasswordInput';
import Icon from '@/Components/Icon/Icon';
import IconButton from '@/Components/Button/IconButton';
import { useApplication } from '../ApplicationProvider';
const ConfirmPassword = ({ setMenuPane, email, password }) => {
    const application = useApplication();
    const { notesAndTagsCount } = application.accountMenuController;
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [isEphemeral, setIsEphemeral] = useState(false);
    const [shouldMergeLocal, setShouldMergeLocal] = useState(true);
    const [error, setError] = useState('');
    const passwordInputRef = useRef(null);
    useEffect(() => {
        var _a;
        (_a = passwordInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    const handlePasswordChange = useCallback((text) => {
        setConfirmPassword(text);
    }, []);
    const handleEphemeralChange = useCallback(() => {
        setIsEphemeral(!isEphemeral);
    }, [isEphemeral]);
    const handleShouldMergeChange = useCallback(() => {
        setShouldMergeLocal(!shouldMergeLocal);
    }, [shouldMergeLocal]);
    const handleConfirmFormSubmit = useCallback((e) => {
        var _a, _b;
        e.preventDefault();
        if (!password) {
            (_a = passwordInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (password === confirmPassword) {
            setIsRegistering(true);
            application
                .register(email, password, isEphemeral, shouldMergeLocal)
                .then(() => {
                application.accountMenuController.closeAccountMenu();
                application.accountMenuController.setCurrentPane(AccountMenuPane.GeneralMenu);
            })
                .catch((err) => {
                console.error(err);
                setError(err.message);
            })
                .finally(() => {
                setIsRegistering(false);
            });
        }
        else {
            setError(STRING_NON_MATCHING_PASSWORDS);
            setConfirmPassword('');
            (_b = passwordInputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
    }, [application, confirmPassword, email, isEphemeral, password, shouldMergeLocal]);
    const handleKeyDown = useCallback((e) => {
        if (error.length) {
            setError('');
        }
        if (e.key === 'Enter') {
            handleConfirmFormSubmit(e);
        }
    }, [handleConfirmFormSubmit, error]);
    const handleGoBack = useCallback(() => {
        setMenuPane(AccountMenuPane.Register);
    }, [setMenuPane]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mb-3 mt-1 flex items-center px-3", children: [_jsx(IconButton, { icon: "arrow-left", title: "Go back", className: "mr-2 flex p-0 text-neutral", onClick: handleGoBack, focusable: true, disabled: isRegistering }), _jsx("div", { className: "text-base font-bold", children: "Confirm password" })] }), _jsxs("div", { className: "mb-3 px-3 text-sm", children: ["Because your notes are encrypted using your password,", ' ', _jsx("span", { className: "text-danger", children: "Standard Notes does not have a password reset option" }), ". If you forget your password, you will permanently lose access to your data."] }), _jsxs("form", { onSubmit: handleConfirmFormSubmit, className: "mb-1 px-3", children: [_jsx(DecoratedPasswordInput, { className: { container: 'mb-2' }, disabled: isRegistering, left: [_jsx(Icon, { type: "password", className: "text-neutral" })], onChange: handlePasswordChange, onKeyDown: handleKeyDown, placeholder: "Confirm password", ref: passwordInputRef, value: confirmPassword }), error ? _jsx("div", { className: "my-2 text-danger", children: error }) : null, _jsx(Button, { primary: true, fullWidth: true, className: "mb-3 mt-1", label: isRegistering ? 'Creating account...' : 'Create account & sign in', onClick: handleConfirmFormSubmit, disabled: isRegistering }), _jsx(Checkbox, { name: "is-ephemeral", label: "Stay signed in", checked: !isEphemeral, onChange: handleEphemeralChange, disabled: isRegistering }), notesAndTagsCount > 0 ? (_jsx(Checkbox, { name: "should-merge-local", label: `Merge local data (${notesAndTagsCount} notes and tags)`, checked: shouldMergeLocal, onChange: handleShouldMergeChange, disabled: isRegistering })) : null] })] }));
};
export default observer(ConfirmPassword);
