import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentType, EditorLineHeightValues, NoteType, PrefKey, classNames, isUIFeatureAnIframeFeature, } from '@standardnotes/snjs';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { MutuallyExclusiveMediaQueryBreakpoints, useMediaQuery } from '@/Hooks/useMediaQuery';
import { useApplication } from '../ApplicationProvider';
import IframeFeatureView from '../ComponentView/IframeFeatureView';
import { ErrorBoundary } from '@/Utils/ErrorBoundary';
import { BlocksEditor } from '../SuperEditor/BlocksEditor';
import { BlocksEditorComposer } from '../SuperEditor/BlocksEditorComposer';
import { useLinkingController } from '@/Controllers/LinkingControllerProvider';
import LinkedItemBubblesContainer from '../LinkedItems/LinkedItemBubblesContainer';
import usePreference from '@/Hooks/usePreference';
import { useResponsiveEditorFontSize } from '@/Utils/getPlaintextFontSize';
import { getScrollParent } from '@/Utils';
export const ReadonlyNoteContent = ({ note, content, showLinkedItems = true, scrollPos, shouldSyncScroll, onScroll, }) => {
    const application = useApplication();
    const linkingController = useLinkingController();
    const isMobileScreen = useMediaQuery(MutuallyExclusiveMediaQueryBreakpoints.sm);
    const componentViewer = useMemo(() => {
        const editorForCurrentNote = application.componentManager.editorForNote(note);
        if (!isUIFeatureAnIframeFeature(editorForCurrentNote)) {
            return undefined;
        }
        const templateNoteForRevision = application.items.createTemplateItem(ContentType.TYPES.Note, content);
        const componentViewer = application.componentManager.createComponentViewer(editorForCurrentNote, {
            readonlyItem: templateNoteForRevision,
        });
        return componentViewer;
    }, [application.componentManager, application.items, content, note]);
    useEffect(() => {
        return () => {
            if (componentViewer) {
                application.componentManager.destroyComponentViewer(componentViewer);
            }
        };
    }, [application, componentViewer]);
    const containerRef = useRef(null);
    const scrollerRef = useRef();
    const setScroller = useCallback(() => {
        var _a;
        if (scrollerRef.current) {
            return;
        }
        scrollerRef.current = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('textarea, .ContentEditable__root');
        if (!scrollerRef.current) {
            return;
        }
        const isScrollerOverflowing = scrollerRef.current.scrollHeight > scrollerRef.current.clientHeight;
        if (!isScrollerOverflowing) {
            const closestScrollParent = getScrollParent(scrollerRef.current);
            if (closestScrollParent) {
                scrollerRef.current = closestScrollParent;
            }
        }
    }, []);
    useEffect(() => {
        var _a;
        if (!shouldSyncScroll) {
            return;
        }
        if (!containerRef.current) {
            return;
        }
        if (!scrollerRef.current) {
            setScroller();
        }
        (_a = scrollerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
            top: scrollPos,
        });
    }, [scrollPos, setScroller, shouldSyncScroll]);
    useEffect(function setupOnScrollForSuper() {
        if (note.noteType !== NoteType.Super) {
            return;
        }
        setScroller();
        const scroller = scrollerRef.current;
        if (!scroller) {
            return;
        }
        const scrollHandler = (event) => {
            onScroll === null || onScroll === void 0 ? void 0 : onScroll(event);
        };
        scroller.addEventListener('scroll', scrollHandler);
        return () => {
            scroller.removeEventListener('scroll', scrollHandler);
        };
    }, [note.noteType, onScroll, setScroller]);
    const lineHeight = usePreference(PrefKey.EditorLineHeight);
    const fontSize = usePreference(PrefKey.EditorFontSize);
    const responsiveFontSize = useResponsiveEditorFontSize(fontSize, false);
    return (_jsxs("div", { className: "flex h-full flex-grow flex-col overflow-hidden", ref: containerRef, children: [_jsx("div", { className: classNames('w-full px-4 pt-4 text-base font-bold', componentViewer && 'pb-4'), children: _jsx("div", { className: "title", children: content.title }) }), showLinkedItems && (_jsx(LinkedItemBubblesContainer, { item: note, linkingController: linkingController, readonly: true, className: { base: 'mt-2 px-4', withToggle: '!mt-1 !pt-0' }, isCollapsedByDefault: isMobileScreen })), componentViewer ? (_jsx("div", { className: "component-view", children: _jsx(IframeFeatureView, { componentViewer: componentViewer }, componentViewer.identifier) })) : content.noteType === NoteType.Super ? (_jsx(ErrorBoundary, { children: _jsx("div", { className: "w-full flex-grow overflow-hidden overflow-y-auto", style: {
                        '--line-height': EditorLineHeightValues[lineHeight],
                        '--font-size': responsiveFontSize,
                    }, children: _jsx(BlocksEditorComposer, { readonly: true, initialValue: content.text, children: _jsx(BlocksEditor, { readonly: true, className: "blocks-editor relative h-full resize-none p-4 text-base focus:shadow-none focus:outline-none", spellcheck: content.spellcheck }) }, content.text) }) })) : (_jsx("div", { className: "relative mt-3 min-h-0 flex-grow overflow-hidden", children: content.text.length ? (_jsx("textarea", { readOnly: true, className: "font-editor h-full w-full resize-none border-0 bg-default p-4 pt-0 text-editor text-text", value: content.text, onScroll: onScroll })) : (_jsx("div", { className: "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-passive-0", children: "Empty note." })) }))] }));
};
