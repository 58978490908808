export class GoogleKeepConverter {
    constructor() {
        this.convert = async (file, { insertNote: createNote, canUseSuper, convertHTMLToSuper, convertMarkdownToSuper, readFileAsText }) => {
            const content = await readFileAsText(file);
            const note = (await this.tryParseAsJson(content, createNote, convertMarkdownToSuper)) ||
                (await this.tryParseAsHtml(content, file, createNote, convertHTMLToSuper, canUseSuper));
            if (note) {
                return {
                    successful: [note],
                    errored: [],
                };
            }
            throw new Error('Could not parse Google Keep backup file');
        };
    }
    getImportType() {
        return 'google-keep';
    }
    getSupportedFileTypes() {
        return ['text/html', 'application/json'];
    }
    isContentValid(content) {
        try {
            const parsed = JSON.parse(content);
            return GoogleKeepConverter.isValidGoogleKeepJson(parsed);
        }
        catch (error) {
            console.error(error);
        }
        return false;
    }
    async tryParseAsHtml(data, file, insertNote, convertHTMLToSuper, canUseSuper) {
        var _a;
        const rootElement = document.createElement('html');
        rootElement.innerHTML = data;
        const headingElement = rootElement.getElementsByClassName('heading')[0];
        const date = new Date((headingElement === null || headingElement === void 0 ? void 0 : headingElement.textContent) || '');
        headingElement === null || headingElement === void 0 ? void 0 : headingElement.remove();
        const contentElement = rootElement.getElementsByClassName('content')[0];
        if (!contentElement) {
            throw new Error('Could not parse content. Content element not found.');
        }
        let content;
        // Convert lists to readable plaintext format
        // or Super-convertable format
        const lists = contentElement.getElementsByTagName('ul');
        Array.from(lists).forEach((list) => {
            list.setAttribute('__lexicallisttype', 'check');
            const items = list.getElementsByTagName('li');
            Array.from(items).forEach((item) => {
                var _a;
                const bulletSpan = item.getElementsByClassName('bullet')[0];
                bulletSpan === null || bulletSpan === void 0 ? void 0 : bulletSpan.remove();
                const checked = item.classList.contains('checked');
                item.setAttribute('aria-checked', checked ? 'true' : 'false');
                if (!canUseSuper) {
                    item.textContent = `- ${checked ? '[x]' : '[ ]'} ${(_a = item.textContent) === null || _a === void 0 ? void 0 : _a.trim()}\n`;
                }
            });
        });
        if (!canUseSuper) {
            // Replace <br> with \n so line breaks get recognised
            contentElement.innerHTML = contentElement.innerHTML.replace(/<br>/g, '\n');
            content = contentElement.textContent;
        }
        else {
            content = convertHTMLToSuper(rootElement.innerHTML);
        }
        if (!content) {
            throw new Error('Could not parse content');
        }
        const title = ((_a = rootElement.getElementsByClassName('title')[0]) === null || _a === void 0 ? void 0 : _a.textContent) || file.name;
        return await insertNote({
            createdAt: date,
            updatedAt: date,
            title: title,
            text: content,
            useSuperIfPossible: true,
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static isValidGoogleKeepJson(json) {
        if (typeof json.textContent !== 'string') {
            if (typeof json.listContent === 'object' && Array.isArray(json.listContent)) {
                return json.listContent.every(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (item) => typeof item.text === 'string' && typeof item.isChecked === 'boolean');
            }
            return false;
        }
        return (typeof json.title === 'string' &&
            typeof json.userEditedTimestampUsec === 'number' &&
            typeof json.isArchived === 'boolean' &&
            typeof json.isTrashed === 'boolean' &&
            typeof json.isPinned === 'boolean' &&
            typeof json.color === 'string');
    }
    async tryParseAsJson(data, createNote, convertMarkdownToSuper) {
        try {
            const parsed = JSON.parse(data);
            if (!GoogleKeepConverter.isValidGoogleKeepJson(parsed)) {
                return null;
            }
            const date = new Date(parsed.userEditedTimestampUsec / 1000);
            let text;
            if ('textContent' in parsed) {
                text = parsed.textContent;
            }
            else {
                text = parsed.listContent
                    .map((item) => {
                    return item.isChecked ? `- [x] ${item.text}` : `- [ ] ${item.text}`;
                })
                    .join('\n');
            }
            text = convertMarkdownToSuper(text);
            return await createNote({
                createdAt: date,
                updatedAt: date,
                title: parsed.title,
                text,
                archived: Boolean(parsed.isArchived),
                trashed: Boolean(parsed.isTrashed),
                pinned: Boolean(parsed.isPinned),
                useSuperIfPossible: true,
            });
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
}
