import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dropdown from '@/Components/Dropdown/Dropdown';
import Icon from '@/Components/Icon/Icon';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import Switch from '@/Components/Switch/Switch';
import { ApplicationEvent, EditorFontSize, EditorLineHeight, EditorLineWidth, PrefKey, PrefDefaults, } from '@standardnotes/snjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Subtitle, Title, Text } from '../../PreferencesComponents/Content';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import { CHANGE_EDITOR_WIDTH_COMMAND } from '@standardnotes/ui-services';
const EditorDefaults = ({ application }) => {
    const [lineHeight, setLineHeight] = useState(() => application.getPreference(PrefKey.EditorLineHeight, PrefDefaults[PrefKey.EditorLineHeight]));
    const handleLineHeightChange = (value) => {
        setLineHeight(value);
        void application.setPreference(PrefKey.EditorLineHeight, value);
    };
    const lineHeightDropdownOptions = useMemo(() => Object.values(EditorLineHeight).map((lineHeight) => ({
        label: lineHeight,
        value: lineHeight,
    })), []);
    const [monospaceFont, setMonospaceFont] = useState(() => application.getPreference(PrefKey.EditorMonospaceEnabled, PrefDefaults[PrefKey.EditorMonospaceEnabled]));
    const toggleMonospaceFont = () => {
        setMonospaceFont(!monospaceFont);
        application.setPreference(PrefKey.EditorMonospaceEnabled, !monospaceFont).catch(console.error);
    };
    const [fontSize, setFontSize] = useState(() => application.getPreference(PrefKey.EditorFontSize, PrefDefaults[PrefKey.EditorFontSize]));
    const handleFontSizeChange = (value) => {
        setFontSize(value);
        void application.setPreference(PrefKey.EditorFontSize, value);
    };
    const fontSizeDropdownOptions = useMemo(() => Object.values(EditorFontSize).map((fontSize) => ({
        label: fontSize,
        value: fontSize,
    })), []);
    const [editorWidth, setEditorWidth] = useState(() => application.getPreference(PrefKey.EditorLineWidth, PrefDefaults[PrefKey.EditorLineWidth]));
    const toggleEditorWidthModal = useCallback(() => {
        application.keyboardService.triggerCommand(CHANGE_EDITOR_WIDTH_COMMAND, true);
    }, [application.keyboardService]);
    useEffect(() => {
        return application.addSingleEventObserver(ApplicationEvent.PreferencesChanged, async () => {
            setEditorWidth(application.getPreference(PrefKey.EditorLineWidth, PrefDefaults[PrefKey.EditorLineWidth]));
        });
    }, [application]);
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Editor" }), _jsxs("div", { className: "mt-2", children: [_jsxs("div", { className: "flex justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Monospace Font" }), _jsx(Text, { children: "Toggles the font style in plaintext and Super notes" })] }), _jsx(Switch, { onChange: toggleMonospaceFont, checked: monospaceFont })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs("div", { children: [_jsx(Subtitle, { children: "Font size" }), _jsx(Text, { children: "Sets the font size in plaintext and Super notes" }), _jsx("div", { className: "mt-2", children: _jsx(Dropdown, { label: "Select the font size for plaintext notes", items: fontSizeDropdownOptions, value: fontSize, onChange: handleFontSizeChange }) })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs("div", { children: [_jsx(Subtitle, { children: "Line height" }), _jsx(Text, { children: "Sets the line height (leading) in plaintext and Super notes" }), _jsx("div", { className: "mt-2", children: _jsx(Dropdown, { label: "Select the line height for plaintext notes", items: lineHeightDropdownOptions, value: lineHeight, onChange: handleLineHeightChange }) })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs("div", { children: [_jsx(Subtitle, { children: "Editor width" }), _jsx(Text, { children: "Sets the max editor width for all notes" }), _jsx("div", { className: "mt-2", children: _jsxs("button", { className: "flex w-full min-w-55 items-center justify-between rounded border border-border bg-default px-3.5 py-1.5 text-left text-base text-foreground md:w-fit lg:text-sm", onClick: toggleEditorWidthModal, children: [editorWidth === EditorLineWidth.FullWidth ? 'Full width' : editorWidth, _jsx(Icon, { type: "chevron-down", size: "normal" })] }) })] })] })] }) }));
};
export default EditorDefaults;
