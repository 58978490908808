import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Icon from '@/Components/Icon/Icon';
import Menu from '@/Components/Menu/Menu';
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import { STRING_EDIT_LOCKED_ATTEMPT } from '@/Constants/Strings';
import { NativeFeatureIdentifier, NoteType, PrefKey, ContentType, } from '@standardnotes/snjs';
import { useCallback, useEffect, useState } from 'react';
import { createEditorMenuGroups } from '../../Utils/createEditorMenuGroups';
import { reloadFont } from '../NoteView/FontFunctions';
import { PremiumFeatureIconClass, PremiumFeatureIconName } from '../Icon/PremiumFeatureIcon';
import { SuperNoteImporter } from '../SuperEditor/SuperNoteImporter';
import MenuRadioButtonItem from '../Menu/MenuRadioButtonItem';
import { Pill } from '../Preferences/PreferencesComponents/Content';
import ModalOverlay from '../Modal/ModalOverlay';
import SuperNoteConverter from '../SuperEditor/SuperNoteConverter';
import MenuSection from '../Menu/MenuSection';
const getGroupId = (group) => group.title.toLowerCase().replace(/\s/, '-');
const ChangeEditorMenu = ({ application, closeMenu, note, onSelect, setDisableClickOutside, }) => {
    const [groups, setGroups] = useState([]);
    const [unableToFindEditor, setUnableToFindEditor] = useState(false);
    const reloadGroups = useCallback(() => {
        const groups = createEditorMenuGroups(application);
        setGroups(groups);
        if (note && note.editorIdentifier) {
            let didFindEditor = false;
            for (const group of groups) {
                for (const item of group.items) {
                    if (item.uiFeature.featureIdentifier === note.editorIdentifier) {
                        didFindEditor = true;
                        break;
                    }
                }
            }
            setUnableToFindEditor(!didFindEditor);
        }
    }, [application, note]);
    useEffect(() => {
        application.items.streamItems([ContentType.TYPES.Component], reloadGroups);
    }, [application, reloadGroups]);
    useEffect(() => {
        reloadGroups();
    }, [reloadGroups]);
    const [currentFeature, setCurrentFeature] = useState();
    const [pendingConversionItem, setPendingConversionItem] = useState(null);
    const showSuperNoteImporter = !!pendingConversionItem &&
        (note === null || note === void 0 ? void 0 : note.noteType) !== NoteType.Super &&
        !!(note === null || note === void 0 ? void 0 : note.text.length) &&
        pendingConversionItem.uiFeature.noteType === NoteType.Super;
    const showSuperNoteConverter = !!pendingConversionItem &&
        (note === null || note === void 0 ? void 0 : note.noteType) === NoteType.Super &&
        pendingConversionItem.uiFeature.noteType !== NoteType.Super;
    useEffect(() => {
        if (note) {
            setCurrentFeature(application.componentManager.editorForNote(note));
        }
    }, [application, note]);
    const premiumModal = usePremiumModal();
    const isSelected = useCallback((item) => {
        if (currentFeature) {
            return item.uiFeature.featureIdentifier === currentFeature.featureIdentifier;
        }
        const itemNoteTypeIsSameAsCurrentNoteType = item.uiFeature.noteType === (note === null || note === void 0 ? void 0 : note.noteType);
        const noteDoesntHaveTypeAndItemIsPlain = !(note === null || note === void 0 ? void 0 : note.noteType) && item.uiFeature.noteType === NoteType.Plain;
        const unknownNoteTypeAndItemIsPlain = (note === null || note === void 0 ? void 0 : note.noteType) === NoteType.Unknown && item.uiFeature.noteType === NoteType.Plain;
        return itemNoteTypeIsSameAsCurrentNoteType || noteDoesntHaveTypeAndItemIsPlain || unknownNoteTypeAndItemIsPlain;
    }, [currentFeature, note]);
    const selectComponent = useCallback(async (uiFeature, note) => {
        if (uiFeature.isComponent && uiFeature.asComponent.conflictOf) {
            void application.changeAndSaveItem.execute(uiFeature.asComponent, (mutator) => {
                mutator.conflictOf = undefined;
            });
        }
        await application.itemListController.insertCurrentIfTemplate();
        await application.changeAndSaveItem.execute(note, (mutator) => {
            const noteMutator = mutator;
            noteMutator.noteType = uiFeature.noteType;
            noteMutator.editorIdentifier = uiFeature.featureIdentifier;
        });
        setCurrentFeature(application.componentManager.editorForNote(note));
        if (uiFeature.featureIdentifier === NativeFeatureIdentifier.TYPES.PlainEditor) {
            reloadFont(application.getPreference(PrefKey.EditorMonospaceEnabled));
        }
    }, [application]);
    const handleConversionCompletion = useCallback((item) => {
        const conversionItem = item || pendingConversionItem;
        if (!conversionItem || !note) {
            return;
        }
        selectComponent(conversionItem.uiFeature, note).catch(console.error);
        closeMenu();
    }, [pendingConversionItem, note, closeMenu, selectComponent]);
    const handleMenuSelection = useCallback(async (menuItem) => {
        if (!menuItem.isEntitled) {
            if (menuItem.uiFeature.featureIdentifier === NativeFeatureIdentifier.TYPES.SuperEditor) {
                premiumModal.showSuperDemo();
                return;
            }
            premiumModal.activate(menuItem.uiFeature.displayName);
            return;
        }
        if (!note) {
            return;
        }
        if (note.locked) {
            application.alerts.alert(STRING_EDIT_LOCKED_ATTEMPT).catch(console.error);
            return;
        }
        if (menuItem.uiFeature.noteType === NoteType.Super) {
            if (note.text.length === 0) {
                handleConversionCompletion(menuItem);
                return;
            }
            if (note.noteType === NoteType.Super) {
                return;
            }
            setPendingConversionItem(menuItem);
            setDisableClickOutside === null || setDisableClickOutside === void 0 ? void 0 : setDisableClickOutside(true);
            return;
        }
        if (note.noteType === NoteType.Super && note.text.length > 0) {
            setPendingConversionItem(menuItem);
            setDisableClickOutside === null || setDisableClickOutside === void 0 ? void 0 : setDisableClickOutside(true);
            return;
        }
        let shouldMakeSelection = true;
        if (menuItem.uiFeature) {
            const changeRequiresAlert = application.componentManager.doesEditorChangeRequireAlert(currentFeature, menuItem.uiFeature);
            if (changeRequiresAlert) {
                shouldMakeSelection = await application.componentManager.showEditorChangeAlert();
            }
        }
        if (shouldMakeSelection) {
            selectComponent(menuItem.uiFeature, note).catch(console.error);
        }
        closeMenu();
        if (onSelect) {
            onSelect(menuItem.uiFeature);
        }
    }, [
        note,
        closeMenu,
        onSelect,
        premiumModal,
        application.alerts,
        application.componentManager,
        setDisableClickOutside,
        handleConversionCompletion,
        currentFeature,
        selectComponent,
    ]);
    const recommendSuper = !note ||
        (note.noteType &&
            [NoteType.Plain, NoteType.Markdown, NoteType.RichText, NoteType.Task, NoteType.Code, NoteType.Unknown].includes(note.noteType));
    const closeSuperNoteImporter = () => {
        setPendingConversionItem(null);
        setDisableClickOutside === null || setDisableClickOutside === void 0 ? void 0 : setDisableClickOutside(false);
    };
    const closeSuperNoteConverter = () => {
        setPendingConversionItem(null);
        setDisableClickOutside === null || setDisableClickOutside === void 0 ? void 0 : setDisableClickOutside(false);
    };
    const managePlugins = useCallback(() => {
        application.openPreferences('plugins');
    }, [application]);
    return (_jsxs(_Fragment, { children: [_jsxs(Menu, { className: "pb-1 pt-0.5", a11yLabel: "Change note type menu", children: [_jsx(MenuSection, { children: _jsxs("div", { className: "flex items-center justify-between py-3 pr-4 md:pb-1 md:pt-0", children: [_jsxs("div", { className: "px-3", children: [_jsx("h2", { className: "text-base font-bold", children: "Choose a note type" }), unableToFindEditor && (_jsx("p", { className: "mr-2 pt-1 text-xs text-warning", children: "Unable to find system editor for this note. Select Manage Plugins to reinstall this editor." }))] }), _jsx("button", { className: "cursor-pointer whitespace-nowrap text-right text-xs text-info", onClick: managePlugins, children: "Manage Plugins" })] }) }), groups
                        .filter((group) => group.items && group.items.length)
                        .map((group) => {
                        const groupId = getGroupId(group);
                        return (_jsx(MenuSection, { children: group.items.map((menuItem) => {
                                const onClickEditorItem = () => {
                                    handleMenuSelection(menuItem).catch(console.error);
                                };
                                return (_jsx(MenuRadioButtonItem, { onClick: onClickEditorItem, className: 'flex-row-reversed py-2', checked: isSelected(menuItem), info: menuItem.uiFeature.description, children: _jsxs("div", { className: "flex flex-grow items-center justify-between", children: [_jsxs("div", { className: `flex items-center ${group.featured ? 'font-bold' : ''}`, children: [group.icon && _jsx(Icon, { type: group.icon, className: `mr-2 ${group.iconClassName}` }), menuItem.uiFeature.displayName, menuItem.isLabs && (_jsx(Pill, { className: "px-1.5 py-0.5", style: "success", children: "Labs" })), menuItem.uiFeature.featureIdentifier === NativeFeatureIdentifier.TYPES.SuperEditor &&
                                                        !isSelected(menuItem) &&
                                                        recommendSuper && (_jsx(Pill, { className: "px-1.5 py-0.5 text-[9px]", style: "info", children: "Recommended" }))] }), !menuItem.isEntitled && (_jsx(Icon, { type: PremiumFeatureIconName, className: PremiumFeatureIconClass }))] }) }, menuItem.uiFeature.uniqueIdentifier.value));
                            }) }, groupId));
                    })] }), _jsx(ModalOverlay, { isOpen: showSuperNoteImporter, close: closeSuperNoteImporter, children: note && (_jsx(SuperNoteImporter, { note: note, application: application, onComplete: handleConversionCompletion, closeDialog: closeSuperNoteImporter })) }), _jsx(ModalOverlay, { isOpen: showSuperNoteConverter, close: closeSuperNoteConverter, className: "md:h-full md:max-h-[90%]", children: note && pendingConversionItem && (_jsx(SuperNoteConverter, { note: note, convertTo: pendingConversionItem, closeDialog: closeSuperNoteConverter, onComplete: handleConversionCompletion })) })] }));
};
export default ChangeEditorMenu;
