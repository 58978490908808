import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import Dropdown from '../Dropdown/Dropdown';
import PreferencesMenuItem from './PreferencesComponents/MenuItem';
const PreferencesMenuView = ({ menu }) => {
    const { selectedPaneId, selectPane, menuItems } = menu;
    const dropdownMenuItems = useMemo(() => menuItems.map((menuItem) => ({
        icon: menuItem.icon,
        label: menuItem.label,
        value: menuItem.id,
    })), [menuItems]);
    return (_jsxs("div", { className: "border-b border-border bg-default px-5 py-2 md:border-0 md:bg-contrast md:px-0 md:py-0", children: [_jsx("div", { className: "hidden min-w-55 flex-col overflow-y-auto px-3 py-6 md:flex", children: menuItems.map((pref) => (_jsx(PreferencesMenuItem, { iconType: pref.icon, label: pref.label, selected: pref.selected, bubbleCount: pref.bubbleCount, hasErrorIndicator: pref.hasErrorIndicator, onClick: () => {
                        selectPane(pref.id);
                    } }, pref.id))) }), _jsx("div", { className: "md:hidden", children: _jsx(Dropdown, { items: dropdownMenuItems, label: "Preferences Menu", value: selectedPaneId, onChange: (paneId) => {
                        selectPane(paneId);
                    }, classNameOverride: {
                        wrapper: 'relative',
                        button: 'focus:outline-none focus:shadow-none focus:ring-none',
                    }, popoverPlacement: "bottom" }) })] }));
};
export default observer(PreferencesMenuView);
