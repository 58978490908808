"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorFromErrorResponse = exports.isErrorResponse = void 0;
function isErrorResponse(response) {
    var _a;
    return ((_a = response.data) === null || _a === void 0 ? void 0 : _a.error) != undefined || response.status >= 400;
}
exports.isErrorResponse = isErrorResponse;
function getErrorFromErrorResponse(response) {
    const embeddedError = response.data.error;
    if (embeddedError) {
        return embeddedError;
    }
    return {
        message: 'Unknown error',
    };
}
exports.getErrorFromErrorResponse = getErrorFromErrorResponse;
