import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BlocksEditor } from '@/Components/SuperEditor/BlocksEditor';
import { BlocksEditorComposer } from '@/Components/SuperEditor/BlocksEditorComposer';
import BlockPickerMenuPlugin from '@/Components/SuperEditor/Plugins/BlockPickerPlugin/BlockPickerPlugin';
import usePreference from '@/Hooks/usePreference';
import { useResponsiveEditorFontSize } from '@/Utils/getPlaintextFontSize';
import { EditorLineHeightValues, PrefKey, classNames } from '@standardnotes/snjs';
import { useRef, useState } from 'react';
import { SuperDemoInitialValue } from './SuperDemoInitialValue';
import { UpgradePrompt } from './UpgradePrompt';
import { useApplication } from '@/Components/ApplicationProvider';
import { useAutoElementRect } from '@/Hooks/useElementRect';
const SuperDemo = ({ hasSubscription, onClose }) => {
    const application = useApplication();
    const lineHeight = usePreference(PrefKey.EditorLineHeight);
    const fontSize = usePreference(PrefKey.EditorFontSize);
    const responsiveFontSize = useResponsiveEditorFontSize(fontSize, false);
    const ctaRef = useRef(null);
    const [demoContainer, setDemoContainer] = useState(null);
    const demoContainerRect = useAutoElementRect(demoContainer, {
        updateOnWindowResize: true,
    });
    return (_jsxs("div", { className: "flex h-full flex-col", ref: setDemoContainer, children: [_jsx("div", { className: classNames('flex-shrink-0 border-b border-border p-4', demoContainerRect && demoContainerRect.height < 500 ? 'hidden md:block' : ''), children: _jsx(UpgradePrompt, { featureName: "Super notes", ctaRef: ctaRef, application: application, hasSubscription: hasSubscription, inline: true, preferHorizontalLayout: true, onClick: onClose }) }), _jsx("div", { className: "relative flex h-full min-h-0 flex-col", style: {
                    '--line-height': EditorLineHeightValues[lineHeight],
                    '--font-size': responsiveFontSize,
                }, children: _jsx(BlocksEditorComposer, { initialValue: SuperDemoInitialValue(), children: _jsx(BlocksEditor, { className: "blocks-editor h-full bg-default", children: _jsx(BlockPickerMenuPlugin, { popoverZIndex: "z-modal" }) }) }) })] }));
};
export default SuperDemo;
